import {Cookie} from '../cookies/js/cookie';

function getCookie(name) {
	let matches = document.cookie.match(new RegExp(
		`(?:^|; )${name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1')}=([^;]*)`,
	));

	return matches ? decodeURIComponent(matches[1]) : undefined;
}

function init() {
	if (document.querySelector('.map-info')) {
		const mapInfo = document.querySelector('.map-info');
		const searchOne = new Cookie('has_visited_consent');

		document.addEventListener('click', (evt) => {
			const withinBoundaries = evt.composedPath().includes(document.querySelector('.map-info__text'));
			searchOne.set('yes', 30);

			if (!withinBoundaries) {
				mapInfo.classList.remove('is-visible');
			}
		});

		if (getCookie('has_cookie_consent') === 'yes' && getCookie('has_visited_consent') === 'yes') {
			mapInfo.classList.remove('is-visible');
		} else {
			mapInfo.classList.add('is-visible');
		}
	}
}

export default {
	init,
};
