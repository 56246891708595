import vars from '../helpers';

let winWidth = window.innerWidth;
const showItemsOnSwipe = {
	$items: $('.home__items'),
	$content: $('.home__content'),
	pageHeight: document.documentElement.offsetHeight,
	windowHeight: document.documentElement.clientHeight,
	offset: document.querySelector('.header').getBoundingClientRect().height + 400,

	init() {
		this.handlers();
	},

	handleScroll() {
		const percent = (window.scrollY || document.documentElement.scrollTop) / (showItemsOnSwipe.windowHeight
			- showItemsOnSwipe.offset);

		showItemsOnSwipe.$items.css({
			opacity: percent < 1 ? percent : 1,
		});

		showItemsOnSwipe.$content.css({
			opacity: 1 - percent,
		});
	},

	handlers() {
		if (!vars.isMobile()) {
			return;
		}

		document.addEventListener('scroll', this.handleScroll, false);

		$('.home__items-show button').on('click', (e) => {
			e.preventDefault();

			vars.scrollTo($('.home__items'), 500, -this.offset + 50);
		});
	},
};

showItemsOnSwipe.init();

window.addEventListener('resize', () => {
	setTimeout(() => {
		if (winWidth !== window.innerWidth) {
			showItemsOnSwipe.init();

			winWidth = window.innerWidth;
		}
	}, 100);
});

