import filter from './filter';

function init() {
	const filterSearch = document.querySelector('.filter-search-js');
	const filterSearchInput = filterSearch.querySelector('.filter-search__input').querySelector('input');
	const filterSearchWpapper = filterSearch.querySelector('.filter-search__wpapper');
	const filterSearchItem = filterSearchWpapper.querySelectorAll('.filter-search__item');
	const filterSearchContent = filterSearchWpapper.querySelector('.filter-search__content');
	const filterSearchItemCopy = filterSearchItem;

	function defaultData() {
		filterSearchItemCopy.forEach((i) => {
			filterSearchContent.appendChild(i);
		});
	}

	// тут ищем совпадающие данные и показываем их
	function sort(value) {
		let items = [];
		filterSearchItem.forEach((item) => {
			const text = item.querySelector('label').innerHTML.toLowerCase();
			if (text.indexOf(value.toLowerCase()) !== -1) {
				items.push(item);
			} else {
				defaultData();
			}
		});

		filterSearchContent.innerHTML = null;

		if (items.length > 0) {
			items.forEach((child) => {
				filterSearchContent.appendChild(child);
			});
		}
	}

	// прячем и показывает фильтр
	filterSearch.addEventListener('click', () => {
		filterSearchWpapper.classList.toggle('is-visible');
	});

	// проверяем количество введенных символов
	filterSearchInput.oninput = () => {
		if (filterSearchInput.value.length >= 2) {
			sort(filterSearchInput.value);
			filterSearchWpapper.classList.add('is-visible');
		} else {
			defaultData();
		}
	};

	// клик мимо фильтра, закрывает фильтр
	document.addEventListener('click', (evt) => {
		const withinBoundaries = evt.composedPath().includes(filterSearch);

		if (!withinBoundaries) {
			filterSearchWpapper.classList.remove('is-visible');
		}
	});

	window.addEventListener('click', (e) => {
		if (e.target.hasAttribute('for') && e.target.closest('.filter-search__content')) {
			const text = e.target.innerText;
			filterSearchInput.value = e.target.innerText;
			$(document).trigger('select-filter-search', text)
			filterSearchWpapper.classList.remove('is-visible');
			defaultData();

			if (document.querySelector('.postcards')) {
				setTimeout(filter.init, 100);
			}
		}
	});
}

export default {
	init,
};
