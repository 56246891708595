function init() {
	if (document.querySelector('.fix-block')) {
		const fixBlock = document.querySelector('.fix-block');
		const fixBlockShare = document.querySelector('.fix-block__share');

		fixBlockShare.addEventListener('click', () => {
			fixBlockShare.classList.toggle('is-visible');
		});

		document.addEventListener('click', (evt) => {
			const withinBoundaries = evt.composedPath().includes(fixBlockShare);

			if (!withinBoundaries) {
				fixBlockShare.classList.remove('is-visible');
			}
		});

		window.addEventListener('scroll', () => {
			if (!(window.pageYOffset < document.documentElement.clientHeight)) {
				fixBlock.classList.add('fix-block--visible');
			} else {
				fixBlock.classList.remove('fix-block--visible');
			}
		});
	}
}

export default {
	init,
};
