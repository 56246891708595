import vars from '../helpers';

function init() {
	const mainEase = 'sine.out';
	const mainDuration = 1;

	gsap.from($('.main__hero-title-duration'), {
		ease: mainEase,
		duration: mainDuration,
		y: 200,
		alpha: 0,
	});
	gsap.from($('.main__hero-title'), {
		ease: mainEase,
		duration: mainDuration,
		y: 150,
		alpha: 0,
		delay: 0.5,
	});
	gsap.from($('.main__hero-text'), {
		ease: mainEase,
		duration: mainDuration,
		y: 150,
		alpha: 0,
		delay: 0.75,
	});

	if (vars.isMobile()) {
		gsap.from($('.main__hero-items'), {
			duration: mainDuration,
			ease: mainEase,
			delay: 1,
			y: 1000,
		});
	} else {
		gsap.from($('.main__hero-items'), {
			duration: mainDuration,
			ease: mainEase,
			x: 1000,
		});
	}

	gsap.from($('.main__hero-btn-showmore'), {
		duration: mainDuration,
		ease: mainEase,
		alpha: 0,
	});

	gsap.from($('.main__hero-map'), {
		ease: mainEase,
		duration: mainDuration,
		opacity: 0,
		delay: 1,
	});
}

export default {
	init,
};
