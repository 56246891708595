import vars from '../helpers';

function init(done) {
	vars.$html.removeClass('is-show-menu');
	const mainEase = 'sine.out';
	const mainDuration = 1;

	gsap.to($('.main__hero-title-duration'), {
		ease: mainEase,
		duration: mainDuration,
		y: -200,
		alpha: 0,
	});
	gsap.to($('.main__hero-title'), {
		ease: mainEase,
		duration: mainDuration,
		y: -150,
		alpha: 0,
		delay: 0.5,
	});
	gsap.to($('.main__hero-text'), {
		ease: mainEase,
		duration: mainDuration,
		y: -150,
		alpha: 0,
		delay: 0.75,
		onComplete: () => {
			done();
		},
	});
	gsap.to($('.main__hero-items'), {
		duration: mainDuration,
		ease: mainEase,
		delay: 0.5,
		x: 1000,
	});
	gsap.to($('.main__hero-btn-showmore'), {
		duration: mainDuration,
		ease: mainEase,
		alpha: 0,
	});
	gsap.to($('.main__hero-map'), {
		ease: mainEase,
		duration: mainDuration,
		opacity: 0,
		delay: 1,
	});
}

export default {
	init,
};
