function filter() {
	const inputValue = document.querySelector('[name="filter"]:checked').value;
	const allItems = document.getElementsByClassName('postcard');

	allItems.forEach((el) => {
		// console.log(el.dataset.type, inputValue)

		if (el.dataset.type === inputValue) {
			el.style.display = 'block';
			el.classList.add('aos-animate');
		} else {
			el.style.display = 'none';
			el.classList.remove('aos-animate');
		}
	});
}

function init() {
	filter();
}

export default {
	init,
};
