import contentAnimation from './contentAnimation';
import contentStartAnimation from './contentStartAnimation';
import sliders from './sliders';
import AOS from 'aos';
import social from './social';
import popupMap from './popupMap';
import horizontalScroll from './horizontalScroll';
import scrollBar from './scrollBar';
import fixBlock from './fixBlock';
import contentNew from "./content-new";
import filterSearch from "./filter-search";

function init() {
	sliders.init();
	contentStartAnimation.init();
	contentAnimation.init();
	social.init();
	popupMap.init();
	fixBlock.init();
	setTimeout(horizontalScroll.init, 500);
	AOS.init({
		once: true,
		duration: 1000,
	});
	scrollBar.init();

	if (document.querySelector('.filter-search-js')) {
		filterSearch.init();
	}

	contentNew.init();
}

export default {
	init,
};
