import './vendor';
import './components/map';
import vars from './helpers';
// import social from './components/social';
import {ieFix} from './vendor/ie-fix';
import {vhFix} from './vendor/vh-fix';
import {actualYear} from './modules/actualYear';
import analytics from './components/analytics';
import header from './components/header';
import lazyLoading from './modules/lazyLoading';
import scrollToAnchor from './modules/scrollToAnchor';
import dropdown from './components/dropdown';
import sliders from './components/sliders';
import AOS from 'aos';
// import contentAnimation from './components/contentAnimation';
// import contentStartAnimation from './components/contentStartAnimation';
import barba from './components/barba';
import homeAnimation from './components/homeAnimation';
import video from './components/video';
import './components/showItemsOnSwipe';
import popupMap from './components/popupMap';
// import horizontalScroll from './components/horizontalScroll';
import stratScriptContent from './components/stratScriptContent';
import footer from './components/footer';
import cookies from './cookies/js/index';
import mapPopaup from './modules/mapPopaup';
import 'simplebar';

ieFix();
vhFix();
actualYear();
scrollToAnchor.init();
dropdown.init();
sliders.init();
// contentAnimation.init();
// contentStartAnimation.init();
homeAnimation.init();
video.init();
analytics.init();
header.init();
// social.init();
popupMap.init();
// horizontalScroll.init();
stratScriptContent.init();
footer.init();
cookies.init();
mapPopaup.init();

AOS.init({
	once: true,
	duration: 1000,
});

if (document.querySelector('[data-barba="container"]')) {
	barba.init();
}

lazyLoading.init();

$('.postcards .dropdown__item label').on('click', () => {
	setTimeout(() => {
		AOS.refresh();
	}, 250);
});

$('.header__burger').on('click', (e) => {
	e.preventDefault();
	e.stopPropagation();

	vars.$html.addClass('is-show-menu');
});

$('.menu__close').on('click', (e) => {
	e.preventDefault();

	vars.$html.removeClass('is-show-menu');
});

vars.$document.on('click', (e) => {
	let $target = $(e.target);

	if (!$target.closest('.menu').length) {
		vars.$html.removeClass('is-show-menu');
	}

	if (!e.target.closest('.main__hero-map-item') &&
		!e.target.classList.contains('main__hero-map-dot')
	) {
		popupMap.close();
	}
});
