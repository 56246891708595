/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import helpers from '../helpers';

const $showcase = $('.showcase');
const options = {
	threshold: 0.55,
};
const observer = new IntersectionObserver(scrollImations, options);

const showcaseGetdata = (e) => {
	const $banner = $(e.target).closest('.showcase');
	const bannerTitle = $banner.find('.showcase__content-title').text();
	const bannerName = helpers.clearText(bannerTitle);
	const bannerUrl = $banner.find('.showcase__content-button').attr('href') || 'without-link';
	const urlPage = window.location.href;

	const obj = {
		id: bannerUrl,
		name: bannerName,
		creative: 'banner',
		position: urlPage,
	};

	return obj;
};

const onPromoClick = (promoObj) => {
	dataLayer.push({
		ecommerce: null,
	});
	dataLayer.push({
		event: 'promotionClick',
		ecommerce: {
			promoClick: {
				promotions: [
					{
						id: promoObj.id,
						name: promoObj.name,
						creative: promoObj.creative,
						position: promoObj.position,
					},
				],
			},
		},
		eventCallback: () => {
			// document.location = promoObj.id;
		},
	});
};

const onPromoImpression = (promoObj) => {
	dataLayer.push({
		ecommerce: null,
	});
	dataLayer.push({
		event: 'promotionView',
		ecommerce: {
			promoView: {
				promotions: [
					{
						id: promoObj.id,
						name: promoObj.name,
						creative: promoObj.creative,
						position: promoObj.position,
					},
				],
			},
		},
	});
};

function scrollImations(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			onPromoImpression(showcaseGetdata(entry));
			observer.unobserve(entry.target);
		}
	});
}

function init() {
	$(document).on('mouseup', '.home__buttons__1', () => {
		dataLayer.push({
			event: 'card_main',
			eventContent: 'Открытки',
		});
	});

	$(document).on('mouseup', '.home__buttons__2', () => {
		dataLayer.push({
			event: 'travel_main',
			eventContent: 'Путешествие с Почтой',
		});
	});

	$(document).on('click mouseup', '.home__item', (e) => {
		const name = $(e.currentTarget).find('.home__item__name').text();

		dataLayer.push({
			event: 'region_main',
			eventContent: helpers.clearText(name),
		});
	});

	$(document).on('click', '.main__hero__item', (e) => {
		const name = $(e.currentTarget).find('.main__hero__item__name').text();

		dataLayer.push({
			event: 'guide_in_regions',
			eventContent: helpers.clearText(name),
		});
	});

	$(document).on('click', '.disign__text-whith-bg-address a', (e) => {
		const url = $(e.currentTarget).attr('href');
		const index = url.substring(url.lastIndexOf('/') + 1);

		dataLayer.push({
			event: 'raspisanie_ops',
			eventContent: index,
		});
	});

	$(document).on('click', '.disign-finish .disign__btn', () => {
		const title = $('.main__hero-title').text();
		const titleClearText = helpers.clearText(title);
		const titleLowerCase = titleClearText.toLowerCase();
		const titleNormal = titleLowerCase[0].toUpperCase() + titleLowerCase.slice(1);

		dataLayer.push({
			event: 'download_guide',
			eventContent: helpers.clearText(titleNormal),
		});
	});

	$(document).on('click', '.disign-finish .content__btn', () => {
		const title = $('.main__hero-title').text();
		const titleClearText = helpers.clearText(title);
		const titleLowerCase = titleClearText.toLowerCase();
		const titleNormal = titleLowerCase[0].toUpperCase() + titleLowerCase.slice(1);

		dataLayer.push({
			event: 'download_guide',
			eventContent: helpers.clearText(titleNormal),
		});
	});

	$(document).on('click', '.other-guides__item', (e) => {
		const name = $(e.currentTarget).find('.other-guides__item-target').text();

		dataLayer.push({
			event: 'guide_in_footer',
			eventContent: helpers.clearText(name),
		});
	});

	$(document).on('click', '.travel__item .dropdown__btn-js', (e) => {
		const title = $(e.currentTarget).closest('.travel__item').find('.travel__item-title').text();

		if (!$(e.currentTarget).hasClass('is-active')) {
			dataLayer.push({
				event: 'dropdown',
				eventContent: helpers.clearText(title),
			});
		}
	});

	$(document).on('click', '.travel__item .postcards__items-link', (e) => {
		const title = $(e.currentTarget).closest('.travel__item').find('.travel__item-title').text();
		const url = $(e.currentTarget).attr('href');
		const urlLastName = url.substring(url.lastIndexOf('/') + 1);

		if (urlLastName === 'parcels') {
			dataLayer.push({
				event: 'button_parcel',
				eventContent: helpers.clearText(title),
			});
		} else {
			dataLayer.push({
				event: 'button_findops',
				eventContent: helpers.clearText(title),
			});
		}
	});

	$(document).on('click', '.postcards:not(.travel) .postcards__items-link', () => {
		dataLayer.push({
			event: 'button_findops',
			eventContent: 'postcards_list',
		});
	});

	$(document).on('click', '.showcase', (e) => {
		onPromoClick(showcaseGetdata(e));
	});

	$(document).on('click', '.disign:not(.disign-banner):not(.disign-finish) a:not(.ops-id-link)', (e) => {
		const url = $(e.currentTarget).attr('href');

		dataLayer.push({
			event: 'link_click',
			eventContent: url,
		});
	});

	$(document).on('click', '.dropdown--guides .dropdown__item', (e) => {
		const title = $(e.currentTarget).text();

		dataLayer.push({
			event: 'map_from_guide',
			eventContent: helpers.clearText(title),
		});
	});

	$(document).on('click', '.dropdown--regions .dropdown__item', (e) => {
		const title = $(e.currentTarget).text();

		dataLayer.push({
			event: 'filter_regions',
			eventContent: helpers.clearText(title),
		});
	});

	// $(document).on('change', '.poscards__items-filter .dropdown__item input', (e) => {
	// 	const title = $(e.currentTarget).text();
	// 	dataLayer.push({
	// 		event: 'filter_postcards',
	// 		eventContent: helpers.clearText(title),
	// 	});
	// });

	$(document).on('select-filter-search', (_, title) => {
		dataLayer.push({
			event: 'filter_postcards',
			eventContent: title
		});
	});

	$(document).on('click', '.filter-btn-map', (e) => {
		const title = $(e.currentTarget).text();

		dataLayer.push({
			event: 'filter_on_map',
			eventContent: helpers.clearText(title),
		});
	});

	$(document).on('click', 'ymaps[class$="content__button_size_small"]', () => {
		dataLayer.push({
			event: 'ymaps_redirect',
		});
	});

	if ($showcase) {
		$showcase.each((i, e) => observer.observe(e));
	}

	// $(document)
	// 	.on('info-on-map', (_, filteredData) => {
	// 		const result = Object.values(filteredData)
	// 			.filter(value => typeof value === 'string')
	// 			.join(', ');
	//
	// 		dataLayer.push({
	// 			event: 'filter_on_map',
	// 			eventContent: result
	// 		});
	// 	});
}

export default {
	init,
};
