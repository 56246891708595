import helpers from '../helpers';

function has(boolean, parent, child, classList) {
	if (boolean) {
		document.querySelectorAll(parent).forEach((item) => {
			if (item.querySelector(child)) {
				item.classList.add(classList);
			}
		});
	} else {
		document.querySelectorAll(parent).forEach((item) => {
			if (item.querySelector(child)) {
				if (item.classList.contains(classList)) {
					item.classList.remove(classList);
				}
			}
		});
	}
}

function init() {
	has(helpers.isMobile(), '.content-new__item .content-new__info', '.content-new__dot.is-active', 'pt-72');
	has(true, '.content-new__item .content-new__box', '.content-new__icon', 'mb-85');
	has(true, '.content-new__item-start .content-new__box', '.content-new__icon', 'mb-96');

	window.addEventListener('resize', () => {
		has(helpers.isMobile(), '.content-new__item .content-new__info', '.content-new__dot.is-active', 'pt-72');
		has(true, '.content-new__item .content-new__box', '.content-new__icon', 'mb-85');
		has(true, '.content-new__item-start .content-new__box', '.content-new__icon', 'mb-96');
	});
}

export default {
	init,
};
