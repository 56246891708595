import Swiper, {Navigation} from 'swiper';
Swiper.use([Navigation]);

function init() {
	// eslint-disable-next-line
	const otherGuidesSlider = new Swiper('.other-guides__slider', {
		direction: 'horizontal',
		navigation: {
			nextEl: '.swiper-btn-next',
			prevEl: '.swiper-btn-prev',
		},
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},

			320: {
				slidesPerView: 1.42,
				spaceBetween: 14,
			},
		},
	});

	// eslint-disable-next-line
	const homeSlider = new Swiper('.home__slider', {
		speed: 600,
		direction: 'horizontal',
		navigation: {
			nextEl: '.swiper-btn-next',
			prevEl: '.swiper-btn-prev',
		},

		breakpoints: {
			1024: {
				slidesPerView: 1,
				spaceBetween: 60,
			},
		},
	});

	// eslint-disable-next-line
	const partnersSlider = new Swiper('.partners__slider', {
		speed: 600,
		direction: 'horizontal',
		navigation: {
			nextEl: '.swiper-btn-next',
			prevEl: '.swiper-btn-prev',
		},

		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},

			320: {
				slidesPerView: 'auto',
				spaceBetween: 6,
			},
		},
	});

	if (window.innerWidth < 1024) {
		homeSlider.disable();
	}

	if (document.querySelector('.home__slider')) {
		window.addEventListener('resize', () => {
			if (window.innerWidth < 1024) {
				homeSlider.slideTo(0);
				homeSlider.disable();
			} else {
				homeSlider.enable();
			}
		});
	}
}

export default {
	init,
};

