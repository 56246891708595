import SimpleBar from 'simplebar';

function hideShowMoreBtn() {
	const allItems = document.querySelectorAll('.main__hero__item');
	const showMoreBtn = document.querySelector('.main__hero-btn-showmore');

	if (allItems.length !== 0 && allItems.length <= 2) {
		showMoreBtn.style.display = 'none';
	}
}

function init() {
	hideShowMoreBtn();

	if (document.querySelector('[data-simplebar]')) {
		// eslint-disable-next-line
		new SimpleBar(document.querySelector('[data-simplebar]'))
	}
}

export default {
	init,
};
