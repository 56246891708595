import videojs from 'video.js';
import vars from '../helpers';

function init() {
	const videoPlayer = $('.home__video__player video')[0];

	if (videoPlayer) {
		const videoJs = videojs(videoPlayer);

		$('.home__video-bnt a').on('click', (e) => {
			e.preventDefault();

			vars.$html.addClass('is-show-video');
			videoJs.play();
		});

		$('.home__video__overlay').on('click', (e) => {
			e.preventDefault();

			if (!videoJs.paused()) {
				videoJs.pause();
				videoJs.currentTime(0);
			}

			vars.$html.removeClass('is-show-video');
		});
	}
}

export default {
	init,
};
