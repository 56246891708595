function init() {
	const $footer = document.querySelector('.footer-new__home');
	if ($footer) {
		let headerHeight = $footer.clientHeight;

		const isFixed = () => $footer.classList.contains('footer-new__home--fix');

		const changeFixedState = (state) => {
			if (state) {
				$footer.classList.add('footer-new__home--fix');
			} else {
				$footer.classList.remove('footer-new__home--fix');
			}
		};

		const changeFixedStateOnScroll = () => {
			if (pageYOffset > headerHeight && !isFixed()) {
				changeFixedState(true);
			} else if (pageYOffset <= headerHeight && isFixed()) {
				changeFixedState(false);
			}
		};

		window.addEventListener('scroll', changeFixedStateOnScroll, false);
	}
}

export default {
	init,
};
