function titleAnimate() {
	const allTitle = document.querySelectorAll('.content__title');

	allTitle.forEach((element) => {
		element.setAttribute('data-aos', 'fade-up');
	});
}

function setDirection(element) {
	const parrent = element.closest('.row');

	if (parrent) {
		const isLeft = parrent.querySelector('.col--text-left');

		if (isLeft) {
			element.setAttribute('data-aos', 'fade-left');
		} else {
			element.setAttribute('data-aos', 'fade-right');
		}
	}
}

function textWhithBgAnimate() {
	const allText = document.querySelectorAll('.content__text-whith-bg');

	allText.forEach((element) => {
		setDirection(element);
	});
}

function imgAnimate() {
	const allText = document.querySelectorAll('.content__image');

	allText.forEach((element) => {
		setDirection(element);
	});
}

function textAnimate() {
	const allText = document.getElementsByTagName('p');

	allText.forEach((element) => {
		if (element.closest('.col--text-right') || element.closest('.col--text-left')) {
			element.setAttribute('data-aos', 'fade-up');

			if (!element.classList.contains('content__text-italic')) {
				element.setAttribute('data-aos-delay', '200');
			}
		}
	});
}

function init() {
	titleAnimate();
	textAnimate();
	imgAnimate();
	textWhithBgAnimate();
}

export default {
	init,
};
