/* eslint-disable no-inner-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable arrow-parens */
// /* eslint-disable */
const routesTest = [
	{
		region: 'владивосток',
		type: 'на автомобиле',
		duration: '2-3 дня',
		points: [
			{
				point: [62.18267, 35.9],
				img: './images/content/radiance-of-the-north/items/radiance-of-the-north.png',
				text: 'Охота<br>за&nbsp;уникальным природным<br>явлением— не&nbsp;хуже, чем в&nbsp;Норвегии, Финляндии и&nbsp;Исландии.',
				link: 'radiance-of-the-north.html#northern-lights',
			},
			{
				point: [65.00417, 32.02137],
				img: './images/content/radiance-of-the-north/items/visiting-saam.png',
				text: 'Древние традиции и катание на оленьей упряжке в туристической саамской деревне Самь-Сыйт.',
				link: 'radiance-of-the-north.html#visiting-saam',
			},
			{
				point: [64.34999055316861, 31.604358128342863],
				img: './images/content/radiance-of-the-north/items/museu.png',
				text: 'Хибины — главный горный массив Кольского полуострова, куда едут любители горных лыж и северного сияния.',
				link: 'koenigsberg-postmen.html#museu',
			},
		],
	},
	{
		region: 'мурманск',
		type: 'на автобусе',
		duration: 'неделя',
		points: [
			{
				point: [69.18267, 35.11905],
				img: './images/content/radiance-of-the-north/items/radiance-of-the-north.png',
				text: 'Охота<br>за&nbsp;уникальным природным<br>явлением— не&nbsp;хуже, чем в&nbsp;Норвегии, Финляндии и&nbsp;Исландии.',
				link: 'radiance-of-the-north.html#northern-lights',
			},
			{
				point: [68.00417, 35.02137],
				img: './images/content/radiance-of-the-north/items/visiting-saam.png',
				text: 'Древние традиции и катание на оленьей упряжке в туристической саамской деревне Самь-Сыйт.',
				link: 'radiance-of-the-north.html#visiting-saam',
			},
			{
				point: [67.61557, 33.66991],
				img: './images/content/radiance-of-the-north/items/museu.png',
				text: 'Хибины — главный горный массив Кольского полуострова, куда едут любители горных лыж и северного сияния.',
				link: 'koenigsberg-postmen.html#museu',
			},
		],
	},
	{
		region: 'карелия',
		type: 'на автобусе',
		duration: 'неделя',
		points: [
			{
				point: [50.18267, 40.11905],
				img: './images/content/radiance-of-the-north/items/radiance-of-the-north.png',
				text: 'Охота<br>за&nbsp;уникальным природным<br>явлением— не&nbsp;хуже, чем в&nbsp;Норвегии, Финляндии и&nbsp;Исландии.',
				link: 'radiance-of-the-north.html#northern-lights',
			},
			{
				point: [51.00417, 41.02137],
				img: './images/content/radiance-of-the-north/items/visiting-saam.png',
				text: 'Древние традиции и катание на оленьей упряжке в туристической саамской деревне Самь-Сыйт.',
				link: 'radiance-of-the-north.html#visiting-saam',
			},
			{
				point: [52.61557, 42.66991],
				img: './images/content/radiance-of-the-north/items/museu.png',
				text: 'Хибины — главный горный массив Кольского полуострова, куда едут любители горных лыж и северного сияния.',
				link: 'koenigsberg-postmen.html#museu',
			},
		],
	},
];

const apiKey = '14f24afc-e9b0-4457-8795-9b6d88181bac';
const activeRouts = [];
const activeFilter = {};
let filteredRouts = [];
const filterListName = [];

if (document.querySelector('#map')) {
	const filterList = [
		document.getElementById('filter-list-region'),
		document.getElementById('filter-list-type'),
		document.getElementById('filter-list-duration'),
	];

	// заполнение фильтра из данных
	function checkFilterItems(data) {
		data.forEach(el => {
			filterList.forEach(filter => {
				const key = filter.id.split('-')[2];

				if (!filterListName.includes(el[key])) {
					activeFilter[key] = '';
					filterListName.push(el[key]);

					filter.insertAdjacentHTML('afterbegin', `
						<div class="dropdown__item filter-item">
							<input class="filter-input-item" id="filter-${el[key]}" type="radio" name="${key}" value="${el[key]}">
							<label class="filter-btn-map" for="filter-${el[key]}">${el[key]}</label>
						</div>
					`);
				}
			});
		});
	}

	// добавление фильтра в массив тегов
	function addFilterTag(e) {
		activeFilter[e.target.name] = e.target.value.toLowerCase();
	}

	checkFilterItems(routesTest);

	// инициализация карты
	ymaps.ready(() => {
		// инициализация карты
		const myMap = new ymaps.Map('map', {
			center: [64.270429, 100.207812],
			zoom: 4,
			controls: [
				// zoomControl,
			],
		}, {
			maxZoom: 18,
			minZoom: 2,
		});

		myMap.controls.add('zoomControl', {
			position: {
				bottom: 50,
				left: 10,
			},
		});

		// получение координат из названия фильтра региона и центрирование карты на этом регионе
		function getCoordinates(serchElement) {
			const serchElementConvert = serchElement.split(' ').join('%20');
			const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${serchElementConvert}`;

			fetch(url, {
				method: 'get',
			}).then((response) => {
				if (response.status < 200 || response.status > 300) {
					let error = new Error(response.statusText);
					error.response = response;
					throw error;
				} else {
					return response.text();
				}
				// eslint-disable-next-line arrow-parens
			}).then(res => {
				const resDom = document.createElement('div');
				resDom.innerHTML = res;
				const coordinates = resDom.querySelector('Point pos').innerHTML.split(' ').reverse();

				return coordinates;
			}).then(coordinates => {
				myMap.setCenter(coordinates);
				myMap.setZoom(6);
			});
		}

		// добавление фильтра в массив фильтров
		document.querySelectorAll('.filter-input-item').forEach((el) => {
			el.addEventListener('change', (e) => {
				e.preventDefault();
				addFilterTag(e);
				filterData(routesTest);

				if (e.target.name === 'region') {
					getCoordinates(e.target.value);
				}
			});
		});

		document.querySelectorAll('.filter-item').forEach((item) => {
			item.querySelector('.filter-btn-map').addEventListener('click', () => {
				if (item.querySelector('.filter-input-item').checked === true) {
					setTimeout(() => {
						cleanFilter();
					}, 100);
				}
			});
		});

		document.querySelector('.reset-filter').addEventListener('click', cleanFilter);

		// вывод маршрутов из массива (с данными или после фильтрации)
		routesTest.forEach(el => {
			addRout(el.points);
		});

		// закрытие попапа по клику на карту
		myMap.events.add('click', () => {
			myMap.balloon.close();
		});

		// клик по маршруту
		myMap.geoObjects.events.add('click', () => {
			setTimeout(() => {
				if (!myMap.balloon.isOpen()) {
					dataLayer.push({
						event: 'ymaps_info',
					});
				}
			}, 100);
		});

		// если открыт балун
		myMap.events.add('balloonopen', () => {
			if (filteredRouts.length) {
				const result = Object.values(filteredRouts[0])
					.filter(value => typeof value === 'string')
					.join(', ');

				dataLayer.push({
					event: 'filter_on_map',
					eventContent: result
				});
			}
		});

		// отчистка фильтра и вывод всех маршрутов
		function cleanFilter() {
			document.querySelectorAll('.filter-input-item').forEach(el => {
				const wrapper = el.closest('.dropdown__container');
				const btn = wrapper.querySelector('.dropdown__btn-js span');
				btn.innerText = btn.dataset.name;
				el.checked = false;
			});

			Object.keys(activeFilter).forEach(el => {
				activeFilter[el] = '';
			});

			routesTest.forEach(el => {
				addRout(el.points);
			});
		}

		// фильтрация списка маршрутов
		function filterData(data) {
			cleanMap();
			filteredRouts = [];

			data.forEach(el => {
				let counter = 0;

				filterList.forEach(filter => {
					const key = filter.id.split('-')[2];

					if (activeFilter[key] === el[key].toLowerCase() || activeFilter[key] === '') {
						counter++;
					}
				});

				if (counter === filterList.length) {
					filteredRouts.push(el);
				}
			});

			filteredRouts.forEach(el => {
				addRout(el.points);
			});
		}

		// функция добавления машрута на карту
		function addRout(rout) {
			const referencePoints = [];

			rout.forEach(el => {
				referencePoints.push(el.point);
			});

			// Создаем мультимаршрут и настраиваем его внешний вид с помощью опций.
			const multiRoute = new ymaps.multiRouter.MultiRoute({
				// eslint-disable-next-line object-shorthand
				referencePoints: referencePoints,
			}, {
				// Внешний вид путевых точек.
				wayPointStartIconColor: '#333',
				wayPointStartIconFillColor: '#B3B3B3',

				// Задаем собственную картинку для последней путевой точки.
				// wayPointFinishIconLayout: "default#image",
				// wayPointFinishIconImageHref: "/upload/graphity/ee5/3jgscb3yux3ggyouiib2ojmsja7gk0u7.png",
				// wayPointFinishIconImageSize: [30, 30],
				// wayPointFinishIconImageOffset: [-15, -15],
				// Позволяет скрыть иконки путевых точек маршрута.
				// wayPointVisible:false,

				// Внешний вид транзитных точек.
				viaPointIconRadius: 7,
				viaPointIconFillColor: '#000088',
				viaPointActiveIconFillColor: '#E63E92',
				// Транзитные точки можно перетаскивать, при этом
				// маршрут будет перестраиваться.
				viaPointDraggable: true,
				// Позволяет скрыть иконки транзитных точек маршрута.
				// viaPointVisible:false,

				// Внешний вид точечных маркеров под путевыми точками.
				pinIconFillColor: '#000088',
				pinActiveIconFillColor: '#B3B3B3',
				// Позволяет скрыть точечные маркеры путевых точек.
				// pinVisible:false,

				// Внешний вид линии маршрута.
				routeStrokeWidth: 2,
				routeStrokeColor: '#000',
				routeActiveStrokeWidth: 6,
				routeActiveStrokeColor: '#000',

				// Внешний вид линии пешеходного маршрута.
				routeActivePedestrianSegmentStrokeStyle: 'solid',
				routeActivePedestrianSegmentStrokeColor: '#00CDCD',

				// Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
				// boundsAutoApply: true
			});

			// Добавляем мультимаршрут на карту.
			myMap.geoObjects.add(multiRoute);

			// Добавляем в массив активных маршрутов
			activeRouts.push(multiRoute);

			// кастомизируем точки и балуны
			rout.forEach((point, index) => {
				customizePoint(multiRoute, point, index);
			});
		}

		// очистка карты при изменении фильтра
		function cleanMap() {
			activeRouts.forEach(el => {
				myMap.geoObjects.remove(el);
			});
		}

		// Функция настройки внешнего вида точки.
		function customizePoint(multiRoute, point, index) {
			// Ждем, пока будут загружены данные мультимаршрута и созданы отображения путевых точек.
			multiRoute.model.events.once('requestsuccess', () => {
				const yandexWayPoint = multiRoute.getWayPoints().get(index);

				MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
					`<a class="map-item" href=${point.link} target="_blank">
						$[[options.contentLayout]]
					</a>`,
					{
						build() {
							this.constructor.superclass.build.call(this);
							this._$element = $('.map-item', this.getParentElement());
							this.applyElementOffset();
							$('.map-item').bind('click', this.onMapItem);
						},

						clear() {
							$('.map-item').unbind('click', this.onMapItem);
						},

						//  Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
						applyElementOffset() {
							this._$element.css({
								left: -(this._$element[0].offsetWidth + 20),
								top: -(this._$element[0].offsetHeight / 2),
								opacity: 1,
							});
						},

						// Используется для автопозиционирования (balloonAutoPan).
						getShape() {
							let position = this._$element.position();

							return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
								[position.left, position.top], [
									position.left + window.innerWidth / 2,
									position.top + window.innerHeight / 2,
								],
							]));
						},

						onMapItem() {
							const mapItemText = this.querySelector('.map-item-text').textContent;

							dataLayer.push({
								event: 'guide_on_map',
								eventContent: mapItemText
							});
						}
					});

				// Создаем балун у метки точки.
				ymaps.geoObject.addon.balloon.get(yandexWayPoint);
				yandexWayPoint.options.set({
					balloonContentLayout: ymaps.templateLayoutFactory.createClass(
						`<div class="row">
							<div class="map-item-image"><img src=${point.img}></div>
							<div class="map-item-text">${point.text}</div>
						</div>`,
					),
					balloonShadow: false,
					balloonLayout: MyBalloonLayout,
					balloonPanelMaxMapArea: 0,
					iconLayout: 'default#image',
					// Своё изображение иконки метки.
					iconImageHref: 'images/Selector.svg',
					// Размеры метки.
					iconImageSize: [30, 30],
					iconImageOffset: [-15, -20],
				});
			});
		}
	});
}
