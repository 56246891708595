import filter from './filter';

let pause = false;
const time = 1000;

function getHeight(elem) {
	return elem.getBoundingClientRect().height;
}

function changeHeight(el, wrapper, height, dropdownContainer) {
	const heightMain = height + Number(window.getComputedStyle(wrapper, null).getPropertyValue('padding-top').replace('px', ''));
	if (el.classList.contains('is-visible')) {
		wrapper.style.height = `${heightMain}px`;
		dropdownContainer.style.zIndex = '10';
		setTimeout(() => {
			wrapper.style.height = 'auto';
		}, time);
	} else {
		wrapper.style.height = `${heightMain}px`;

		// eslint-disable-next-line no-return-assign
		setTimeout(() => {
			wrapper.style.height = 0;
			dropdownContainer.style.zIndex = '';
		}, 0);
	}
}

function toggleClass(dropdownBtn) {
	const dropdownContent = dropdownBtn.parentNode.querySelector('.dropdown__content');
	const parent = dropdownBtn.parentNode;
	const dropdownWrapper = parent.querySelector('.dropdown__wrapper');
	const contentHeight = getHeight(dropdownContent);

	dropdownBtn.classList.toggle('is-active');
	parent.classList.toggle('is-visible');

	changeHeight(parent, dropdownWrapper, contentHeight, dropdownBtn.parentNode);
}

function replacePlaceHolder(e) {
	const dropdownContainer = e.target.closest('.dropdown__container');
	const btn = dropdownContainer.querySelector('.dropdown__btn-js');

	btn.querySelector('span').textContent = e.target.innerText;
}

function init() {
	const allDropdown = document.getElementsByClassName('dropdown__btn-js');

	window.addEventListener('click', (e) => {
		const dropdownBtn = e.target.closest('.dropdown__btn-js');
		if (dropdownBtn || e.target.classList.contains('dropdown__btn-js')) {
			if (!pause) {
				allDropdown.forEach((el) => {
					if (!e.target.closest('.filter-group')) {
						if (el.classList.contains('is-active') && el !== e.target) {
							toggleClass(el);
						}
					} else if (!el.closest('.filter-group')) {
						if (el.classList.contains('is-active') && el !== e.target) {
							toggleClass(el);
						}
					}
				});
				toggleClass(dropdownBtn);
				pause = true;

				setTimeout(() => {
					pause = false;
				}, time);
			}
		} else if (!pause) {
			allDropdown.forEach((el) => {
				if (el.classList.contains('is-active')) {
					toggleClass(el);
				}
			});
		}

		if (e.target.hasAttribute('for') && e.target.closest('.dropdown__container')) {
			replacePlaceHolder(e);

			if (document.querySelector('.postcards')) {
				setTimeout(filter.init, 100);
			}
		}
	});
}

export default {
	init,
};

