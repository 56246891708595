import './../vendor';
import barba from '@barba/core';
import contentExitAnimation from './contentExitAnimation';
import stratScriptContent from './stratScriptContent';

function checkPageAndRedirect(data, splitter, namePage) {
	if (data.trigger.href.split(splitter)[data.trigger.href.split(splitter).length - 1] === namePage) {
		barba.force(data.trigger.href);
	}
}

function init() {
	barba.init({
		transitions: [{
			name: 'default-transition',

			leave(data) {
				// page reload on homepage
				if (data.trigger.href) {
					checkPageAndRedirect(data, '', '/');
					checkPageAndRedirect(data, '/', 'postcards.html');
					checkPageAndRedirect(data, '/', 'map.html');
					checkPageAndRedirect(data, '/', 'partners.html');
					checkPageAndRedirect(data, '/', 'travel.html');
				}

				let done = this.async();

				return contentExitAnimation.init(done);
			},

			async enter() {
				stratScriptContent.init();
			},
		}],
	});
}

export default {
	init,
};
