import {cookieConsentChecker} from './cookie-consent-checker';

const init = () => {
	window.addEventListener('DOMContentLoaded', () => {
		window.addEventListener('load', () => {
			cookieConsentChecker();
		});
	});
};

export default {
	init,
};
