const timer = 200;

function close() {
	const allPopup = document.getElementsByClassName('main__hero-map-item');

	allPopup.forEach((el) => {
		el.style.opacity = 0;
		setTimeout(() => {
			el.style.top = 0;
			el.style.display = 'none';
		}, timer);
	});
}

function open(e) {
	close();
	const popup = document.getElementById(`item-${e.target.dataset.id}`);
	const posX = e.clientX;
	const posY = e.clientY;

	setTimeout(() => {
		popup.style.display = 'flex';

		setTimeout(() => {
			popup.style.opacity = 1;
		}, timer);

		popup.style.left = `${posX - 140}px`;

		if (posY - popup.offsetHeight - 16 + window.scrollY <= 0) {
			popup.style.top = 0;
		} else if (window.innerWidth - popup.getBoundingClientRect().right < 0) {
			popup.style.right = '20px';
			popup.style.left = 'auto';
			popup.style.top = `${posY - popup.offsetHeight + window.scrollY}px`;
		} else {
			popup.style.top = `${posY - popup.offsetHeight + window.scrollY}px`;
		}
	}, timer);
}

function init() {
	$('.main__hero-map-dot').on('click', (e) => {
		open(e);
	});
}

export default {
	init,
	open,
	close,
	timer,
};
