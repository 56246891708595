import vars from '../helpers';

function init() {
	if (document.querySelector('.home')) {
		const animate = () => {
			gsap.from($('.home__image'), {
				y: 200,
				alpha: 0,
				duration: 1,
			});
			gsap.from($('.home__title__line:nth-child(1)'), {
				y: 150,
				alpha: 0,
				duration: 1,
				delay: 0.5,
			});
			gsap.from($('.home__title__line:nth-child(2)'), {
				y: 150,
				alpha: 0,
				duration: 1,
				delay: 0.75,
			});
			gsap.from($('.home__items'), {
				x: '100vw',
				duration: 1.5,
				delay: 1,
			});
			gsap.from($('.home__text, .home__buttons, .home__video-bnt, .home__items-show'), {
				alpha: 0,
				duration: 1,
				delay: 1.75,
			});
		};

		vars.$window.on('load', () => {
			vars.$html.removeClass('is-show-preloader');
			animate();
		});
	}
}

export default {
	init,
};
