const slider = document.querySelector('.dropdown__list');
let sliderOffsetLeft;

if (slider) {
	sliderOffsetLeft = slider.getBoundingClientRect().left;
}

function init() {
	if (slider) {
		let isDown = false;
		let startX;
		let scrollLeft = 0;

		slider.addEventListener('touchstart', (e) => {
			isDown = true;
			slider.classList.add('active');
			startX = e.targetTouches[0].pageX;
			scrollLeft = slider.getBoundingClientRect().left;
		});

		slider.addEventListener('touchend', () => {
			scrollLeft = slider.getBoundingClientRect().left;
			isDown = false;
			slider.classList.remove('active');
		});

		slider.addEventListener('touchmove', (e) => {
			if (!isDown) {
				return;
			}

			e.preventDefault();

			const x = e.targetTouches[0].pageX;
			const walk = x - startX;
			let move = scrollLeft + walk;

			if (move > 0) {
				move = 0;
			} else if (move <= window.innerWidth - slider.offsetWidth - sliderOffsetLeft * 2) {
				move = window.innerWidth - slider.offsetWidth - sliderOffsetLeft * 2;
			}
			slider.style.transform = `translate(${move}px, 0)`;
		});
	}
}

export default {
	init,
};
